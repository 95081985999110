<template>
  <div>
    <h4>{{ imageLabel }}</h4>
    <br>
    <div
      class="upload"
    >
      <v-row>
        <v-col
          cols="5"
          md="5"
        >
          <v-file-input
            v-model="entityData[imageName].file"
            counter
            show-size
            small-chips
            placeholder="Choisir un fichier"
            truncate-length="25"
            :prepend-icon="null"
            @change="updateImageFile"
          >
            <template #selection="{ text }">
              <v-chip
                small
                label
                outlined
                color="primary"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-text-field
            v-model="entityData[imageName].title"
            label="Titre de l'image"
            class="mb-5"
            dense
            placeholder="Titre de l'image"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="imagePreview || entityData[imageName].id"
      class="preview"
    >
      <img
        :src="imagePreview ? imagePreview : apiUrl() + '/media/' + entityData[imageName].id"
        alt="image"
        class="float-left"
      />
      <v-btn
        v-if="!localEntityData[imageName].id"
        x-small
        class="mr-2 float-left"
        color="info"
        @click="uploadImage(imageName)"
      >
        <span>
          Valider l'image
        </span>
      </v-btn>

      <v-btn
        v-if="localEntityData[imageName].id"
        x-small
        class="mr-2 float-left"
        color="error"
        @click="deleteImage(imageName)"
      >
        <span>
          Supprimer l'image
        </span>
      </v-btn>
      <div class="clear"></div>
    </div>
    <br>
  </div>
</template>

<script>
import config from '../../config'
import Vue from 'vue'

export default {
  name: 'ImageHandler',
  props: {
    entityData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    valid: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    loading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    successMessage: {
      type: String,
      default: () => {
        return null
      }
    },
    snackbar: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    imageLabel: {
      type: String,
      default: () => {
        return null
      }
    },
    imageName: {
      type: String,
      default: () => {
        return null
      }
    },

    icons: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      imagePreview: null,
      selectedFile: null,
      previewImage: null,
      localEntityData: JSON.parse(JSON.stringify(this.entityData))
    }
  },
  methods: {
    apiUrl () {
      return config.apiUrl
    },
    async uploadImage() {
      this.$emit('updateValid', false)
      this.$emit('updateLoading', true)

      const formData = new FormData()

      formData.append('media', this.entityData[this.imageName].file)

      Vue.prototype.$http
        .post(config.apiUrl + '/api/medias', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.status === 201) {
            const media = response.data

            this.localEntityData[this.imageName].id = media.id
            this.localEntityData[this.imageName].name = media.name
            this.localEntityData[this.imageName].title = this.entityData[this.imageName].title

            this.$emit('updateSnackbar', true)
            this.$emit('updateSuccessMessage', 'Image envoyé avec succès.')
            this.$emit('update', this.localEntityData)
          }

          this.$emit('updateValid', true)
          this.$emit('updateLoading', false)
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            // const data = response.data
            // this.errors = data.violations
          }

          this.$emit('updateValid', true)
          this.$emit('updateLoading', false)
        })
    },
    updateImageFile (data) {
      this.localEntityData[this.imageName].id = null
      this.localEntityData[this.imageName].name = null

      if (!this.entityData[this.imageName].title) {
        const fileName = data.name.split('.').slice(0, -1).join('.')

        this.localEntityData[this.imageName].title = fileName
        this.entityData[this.imageName].title = fileName
      }

      this.imagePreview = URL.createObjectURL(data)
    },
    deleteImage () {
      this.localEntityData[this.imageName].id = null
      this.localEntityData[this.imageName].name = null
      this.localEntityData[this.imageName].title = null

      this.entityData[this.imageName].id = null
      this.entityData[this.imageName].name = null
      this.entityData[this.imageName].title = null

      this.imagePreview = null
    }
  }
}
</script>

<style>
  .preview {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .preview button {
    margin-bottom: auto;
    margin-left: 1rem;
  }

  .preview img {
    max-width: 20%;
  }
</style>
